import { OrderItem } from './../models/order_item';
import { TokenizationRequest } from './../models/tokenization_request';
import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../services/payment.service';
import { TokenizationRequestViewModel } from '../models/tokenization_request_viewmodel';
import { HttpErrorResponse } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit {
  referenceNumber: string;
  amount: number;
  entityType: string;
  paymentId: string;
  currency: string;
  checkout_URL: string;
  order_items: OrderItem[];

  //Array of inputs received from API for tokenization request like calculated signature, command, customer name, email etc
  otherInputControls: TokenizationRequest;


  
  @BlockUI('orderDetailsLoading') orderDetailsLoading:NgBlockUI;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.referenceNumber = params['reference_number'];
      this.amount = params['amount'];
      this.entityType = params['entity_type'];
      this.paymentId = params['payment_id'];
      if (!this.referenceNumber) {
        // this.navigationExtras.state.errorCode = '01';
        // this.navigationExtras.state.errorMessage =
        //   'Application Reference Missing';
        this.router.navigate(['/error'], {
          queryParams: {
            errorCode: '01',
            errorMessage: 'Reference Number Missing',
          },
        });
      } else if (!this.amount) {
        // this.navigationExtras.state.errorCode = '02';
        // this.navigationExtras.state.errorMessage = 'Amount Missing';
        this.router.navigate(['/error'], {
          queryParams: { errorCode: '02', errorMessage: 'Amount Missing' },
        });
      } else if (!this.entityType) {
        this.router.navigate(['/error'], {
          queryParams: {
            errorCode: '03',
            errorMessage: 'Entity Type Missing',
          },
        });
      } else if (!this.paymentId) {
        this.router.navigate(['/error'], {
          queryParams: {
            errorCode: '04',
            errorMessage: 'Payment Id Missing',
          },
        });
      } else {
        this.orderDetailsLoading.start();
        this.paymentService
          .getPaymentDetails(
            this.referenceNumber,
            this.amount,
            this.entityType,
            this.paymentId
          )
          .subscribe(
            (tokenizationVM: TokenizationRequestViewModel) => {
              this.orderDetailsLoading.stop();
              this.otherInputControls = tokenizationVM.tokenizationRequest;
              this.amount = tokenizationVM.amount;
              this.currency = tokenizationVM.currency;
              this.checkout_URL = tokenizationVM.checkout_URL;
              this.order_items = [
                {
                  agent: tokenizationVM.agent_Name,
                  product: tokenizationVM.product_Name,
                  provider: tokenizationVM.provider_Name,
                  amount: tokenizationVM.amount,
                },
              ];
            },
            (error: HttpErrorResponse) => {
              // this.navigationExtras.state.errorCode = '';
              // this.navigationExtras.state.errorMessage = error.message;
              this.router.navigate(['/error'], {
                queryParams: {
                  errorCode: error.status,
                  errorMessage: error.error,
                },
              });
            }
          );
      }
    });
  }

  onSubmit() {
  }
}
